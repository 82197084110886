<template>
  <div style="margin-top: 20px;">
    <el-table :data="data.tableData" style="width: 100%" :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }">
      <el-table-column prop="fundId" label="基金代码" />
      <el-table-column prop="fundName" label="基金名称" />
      <el-table-column prop="freeShare" label="持仓份额" />
      <el-table-column prop="frozenShare" label="冻结份额" />
	  <el-table-column prop="unitNetAssets" label="基金单位净值" />
      <el-table-column prop="marketValue" label="市值" />
    </el-table>
  </div>
</template>

<script setup>
import { reactive, toRefs ,onMounted} from 'vue';
import {listCapitalShares} from '../../api.js';
	onMounted(() => {
		listFund();
	})
	const listFund = () =>{
		listCapitalShares({"acid":sessionStorage.getItem("acid")}).then(res => {
			data.tableData = res.data.data.list;
		})
	};
	
    let data = reactive({
      tableData: []
    })

</script>